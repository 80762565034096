/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // MOBILE
        if ($(window).width() < 765){ 
          $(".produtos-carrossel-class").removeClass("owl-carousel").removeClass("produtos-carrossel");
          $(".produtos-carrossel-class .layers2").addClass("row");
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.ancora').click(function() { // a[href*=#]:not([href=#])
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
              $('html,body').animate({
              scrollTop: target.offset().top //-80
              }, 800);
              return false;
          }
          }
        });

        $('.slide-home-header').owlCarousel({
          loop: true,
          autoplay: true,
          autoplayHoverPause: true,
          items:1,
          nav:true,
          dots: false,
          navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
          responsiveClass: true,
          //autoplayTimeout: 8000,
        });
        
        $('.slide-depo').owlCarousel({
          loop: true,
          autoplay: true,
          autoplayHoverPause: true,
          //margin:20,
          navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
          responsiveClass: true,
          //autoplayTimeout: 8000,
          responsive:{
              0:{
                  items:1,
                  nav:true
              },
              600:{
                  items:1,
                  nav:true
              },
              1000:{
                  items:1,
                  nav:true,
                  loop:true
              }
          }
        });

        $('.slide-clientes').owlCarousel({
          loop: true,
          autoplay: true,
          autoplayHoverPause: true,
          responsiveClass: true,
          margin:0,
          responsive:{
              0:{
                  items:2
              },
              600:{
                  items:5
              },
              1000:{
                  items:10
              }
          }
        });

        var homeSlider = $('.produtos-carrossel').owlCarousel({
          loop: true,
          autoplay: true,
          autoplayHoverPause: true,
          //margin:20,
          navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
          responsiveClass: true,
          //autoplayTimeout: 8000,
          responsive:{
              0:{
                  items:1,
                  nav:true
              },
              600:{
                  items:1,
                  nav:true
              },
              1000:{
                  items:1,
                  nav:true,
                  loop:true
              }
          }
        });

        homeSlider.on('changed.owl.carousel', function() {
          setTimeout(function(){
            var index = $('.owl-item.active').find('.layers2').data('item');
            $('.under-slider .slider-small-item').removeClass('active');
            $('.under-slider .slider-small-item.item-' + index).addClass('active');
          }, 250);
    
        });
    
        $('.slider-small-item').on('click', function(){
            var index = $(this).data('item');
            homeSlider.trigger('to.owl.carousel', index-1);
            // para resetar o Time
            homeSlider.trigger('stop.owl.autoplay');
            homeSlider.trigger('play.owl.autoplay');
          return false;
        });

        // ATUALIZA ITENS DO HEADER
        $.getJSON(base_url+'carrinho/upcarrinhoheader', function(res){
          if(res.erro == 0){
            $('.total-carrinho-header').html(res.itens);
          }
        });

        // ADICIONAR PRODUTO NO CARRINHO
        // Função Tira o AVISO
        var SetTime;
        function timeoutHandle(){
          SetTime = setTimeout(function(){
            $('.grupo-alert-carrinho').removeClass('mostrar-aviso-carrinho');
          }, 7000);
        }
        $('.btn-incluir-pdt').click(function() {
          //alert('add carrinho!');
          var id_produto = $(this).data('id');
          var transp = $(this).data('transp');
          var qtd_minima = $(this).data('qtd');
          $('.grupo-alert-carrinho').removeClass('mostrar-aviso-carrinho');
          // reseta setTimeout
          clearTimeout(SetTime);
          //alert(qtd_minima);
          $.ajax({
            type: 'post',
            url: base_url+'carrinho/Add',
            data: {id:id_produto, tipo:transp, qtd:qtd_minima},
            dataType: 'JSON'
          }).then (function(res){
            if(res.erro == 0){
              //alert('produto adicionado!');
              // Mensagem do AVISO
              $('.alert-carrinho').html('<h4>'+res.msg+'</h4>');
              // Incrementa carrinho HEADER
              $('.total-carrinho-header').html(res.itens);
              // Mostra AVISO
              $('.grupo-alert-carrinho').addClass('mostrar-aviso-carrinho');
              // Tira o aviso
              timeoutHandle();
            }else{
              alert(res.msg);
            }
          },function(){
            alert('erro!');
          });
        });
        /////////////////////////////////////////////////////
        
        // FUNÇÕES PARA INCREMENTO DE QUANTIDADE NO CARRINHO ////////////
        $(document).on('click','.btn-increment',function(){
          var id = $(this).data('cor');
          var pdt = $(this).data('id');
          $('#aviso-decrement-'+pdt).html('');
          if($('#input'+id).val() == 504){
            $('#aviso-decrement-'+pdt).html('<span class="text-danger">Para pedidos maiores de 504 peças, favor especificar no campo "Observação."</span>');
            //$('#btn-up-qdt-'+id).prop("disabled", true);
          }else{
            $('#input'+id).val( parseInt($('#input'+id).val(), 10) + 24);
            $('#btn-up-qdt-'+id).prop("disabled", false);
          }
        });
        $(document).on('click','.btn-decrement',function(){
          var id = $(this).data('cor');
          var pdt = $(this).data('id');
          var qtd_minima = $(this).data('qtd');
          $('#aviso-decrement-'+pdt).html('');
          if($('#input'+id).val() == qtd_minima){
            $('#aviso-decrement-'+pdt).html('<span class="text-danger">Pedido mínimo de '+qtd_minima+' peças</span>');
            //$('#btn-up-qdt-'+id).prop("disabled", true);
          }else{
            $('#input'+id).val( parseInt($('#input'+id).val(), 10) - 24);
            $('#btn-up-qdt-'+id).prop("disabled", false);
          }
        });

        // Grava cor na sessão
        $(document).on('click','.btn-up-qdt',function(){
          var id_produto = $(this).data('id');
          var id_cor = $(this).data('cor');
          var valor_qtd = $('#input'+id_cor).val();
          $(this).prop("disabled", true);
          //alert('produto:'+id_produto+'/cor:'+id_cor+'/qtd:'+valor_qtd);
          $.ajax({
            type: 'post',
            url: base_url+'carrinho/Altera',
            data: {id:id_produto,cor:id_cor,qtd:valor_qtd},
            dataType: 'JSON'
          }).then(function(res){
            if(res.erro == 0){
              $('#aviso-decrement-'+id_produto).html('<span class="text-success">'+res.msg+'</span>');
            }else{
              $('#aviso-decrement-'+id_produto).html('erro!');
            }
          },function(){
            alert('erro no controller');
            //$('.retorno-calculo-ajax').html('');
          });
        });

        // Grava qtd produto transparente
        $(document).on('click','.btn-up-qdt-transparente',function(){
          var id_produto = $(this).data('id');
          var id_cor = $(this).data('cor');
          var valor_qtd = $('#inputtransparente_'+id_produto).val();
          $(this).prop("disabled", true);
          //alert('produto:'+id_produto+'/cor:'+id_cor+'/qtd:'+valor_qtd);
          $.ajax({
            type: 'post',
            url: base_url+'carrinho/Altera',
            data: {id:id_produto,cor:id_cor,qtd:valor_qtd},
            dataType: 'JSON'
          }).then(function(res){
            if(res.erro == 0){
              $('#aviso-decrement-'+id_produto).html('<span class="text-success">'+res.msg+'</span>');
            }else{
              $('#aviso-decrement-'+id_produto).html('erro!');
            }
          },function(){
            alert('erro no controller');
            //$('.retorno-calculo-ajax').html('');
          });
        });

        // Selecionando COR
        $('.check-add-cor-carrinho').click(function(){
          var id_produto = $(this).data('id');
          var id_cor = $(this).data('cor');
          var qtd_minima = $(this).data('qtd');
          var load = base_url+'dist/site/images/load.gif';
          if($('#Cor'+id_cor).is(':checked')) {
            // APAGAR ////////////////////////
            // Carregador
            $(this).append('<img class="loading" src="'+load+'" width="40px" style="margin-top:-3px;margin-left:-35px;position:absolute;">');
            $.ajax({
              type: 'post',
              url: base_url+'carrinho/ApagaCor',
              data: {id:id_produto,cor:id_cor},
              dataType: 'JSON'
            }).then(function(res){
              if(res.erro == 0){
                $('.item-remove-'+id_cor).parent().removeClass('corescolhida');
                $('.item-remove-'+id_cor+' span').remove();
                //$('#aviso-decrement-'+id_produto).html('<span class="text-success">'+res.msg+'</span>');
                
                // SE NÃO EXISTIR NENHUMA COR ESCOLHIDA
                if($('.group-checkbox'+id_produto+' li :checkbox:checked').length == 0){
                  // RECOLOCA REQUIRED EM TODAS
                  $('input:checkbox[name="checkcor'+id_produto+'[]"]').prop("required", true);
                }
                $('.loading').remove();
              }else{
                $('#aviso-decrement-'+id_produto).html('erro!');
              }
            },function(){
              alert('erro no controller');
              //$('.retorno-calculo-ajax').html('');
            });
          }else{
            // ADICIONAR ///////////////////////
            // Carregador
            $(this).append('<img class="loading" src="'+load+'" width="40px" style="margin-top:-3px;margin-left:-35px;position:absolute;">');
            $.ajax({
              type: 'post',
              url: base_url+'carrinho/Altera',
              data: {id:id_produto,cor:id_cor,qtd:qtd_minima},
              dataType: 'JSON'
            }).then(function(res){
              if(res.erro == 0){
                $('#item-qtd-'+id_cor).append(
                  '<span>'+
                  '<input type="button" name="btn" class="btn-decrement" data-id="'+id_produto+'" data-cor="'+id_cor+'" data-qtd="'+qtd_minima+'" value="-" >'+
                  '<input type="number" class="input-qtd" name="'+id_cor+'" id="input'+id_cor+'" value="'+qtd_minima+'" readonly>'+
                  '<input type="button" name="btn" class="btn-increment" data-id="'+id_produto+'" data-cor="'+id_cor+'" value="+">'+
                  '<button class="btn-up btn-up-qdt" id="btn-up-qdt-'+id_cor+'" data-id="'+id_produto+'" data-cor="'+id_cor+'" disabled><i class="fas fa-sync-alt"></i></button>'+
                  '</span>'
                  );
                $('#item-qtd-'+id_cor).parent().addClass('corescolhida');
                $('input:checkbox[name="checkcor'+id_produto+'[]"]').prop("required", false);
                $('.loading').remove();
              }else{
                $('#aviso-decrement-'+id_produto).html('erro!');
              }
            },function(){
              alert('erro no controller');
              //$('.retorno-calculo-ajax').html('');
            });
          }
        });

        // $('.btn-up-cor-carrinho').click(function(){
        //   var id_produto = $(this).data('id');
        //   var id_cor = $(this).data('cor');
        //   //alert(cor);
        //   $.ajax({
        //     type: 'post',
        //     url: base_url+'carrinho/GravaCor',
        //     data: {id:id_produto,cor:id_cor},
        //     dataType: 'JSON'
        //   });
        // });
        ///////////////////////////////////////////////////////////

        $('[data-toggle="tooltip"]').tooltip();

        $('.btn-produto-personalizado').click(function(){
          // $('.produto-personalizado').toggleClass('personalizado-ativo');
          // $('.formulario-carrinho').toggleClass('formulario-carrinho-ativo');
          // $('.nao-personalizado').toggleClass('btn-inativo');
          // $('#btn-submit').toggleClass('btn-submit-ativo');
          // $(this).removeClass('btn-inativo');
          if($('#persona').not(':checked')) {
            $('.produto-personalizado').addClass('personalizado-ativo');
            $('.formulario-carrinho').addClass('formulario-carrinho-ativo');
            $('#btn-submit').addClass('btn-submit-ativo');
          }
        });

        $('.btn-produto-sem-personalizacao').click(function(){
          // $('.produto-personalizado').removeClass('personalizado-ativo');
          // $('.formulario-carrinho').toggleClass('formulario-carrinho-ativo');
          // $('.personalizado').toggleClass('btn-inativo');
          // $('#btn-submit').toggleClass('btn-submit-ativo');
          // $(this).removeClass('btn-inativo');
          if($('#persona').not(':checked')) {
            $('.produto-personalizado').removeClass('personalizado-ativo');
            $('.formulario-carrinho').addClass('formulario-carrinho-ativo');
            $('#btn-submit').addClass('btn-submit-ativo');
          }
        });
        
        // $('#btn-submit').on('click', function() {
        //   //alert('veio aqui!');
        //   if($('.group-checkbox1 li :checkbox:checked').length > 0){
        //     return true;
        //   }else{
        //     alert('necessário ao menos uma cor escolhida! 1');
        //     return false;
        //   }
        // });

        $('.toggle').click(function(){
          $('.menu-principal').toggleClass('menu-ativo');
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


